import React, { Component } from "react"
// import { Collapse } from "antd";
// import { Tabs } from 'antd';
import { Link } from 'gatsby'
import _ from "lodash"
// import Slider from "react-slick"
// import InputMask from 'react-text-mask'
// import Geosuggest from 'react-geosuggest'
import Header from "./header"
import BusinessForm from "./business-form"
import BusinessBanner from "../../images/business-banner.jpg"
import CenterImg from '../../images/grayimg-center.svg'
import LeftImg from '../../images/whiteimg-left.png'
import RightImg from '../../images/whiteimg-right.gif'
import ExpertImg from '../../images/how-expert.svg'
import LiveImg from '../../images/how-live.svg'
import DataImg from '../../images/how-data.svg'
import ResImg from '../../images/how-responsible.svg'

import ImgPlace from '../../images/imgplace.svg'
import ImgDriver from '../../images/imgdriver.svg'
import ImgCloud from '../../images/gps-vehicle-monitoring-system.gif'
import ImgSensors from '../../images/location_tracker.gif'

import YardMini from '../../images/yardmini.svg'
import Yard10 from '../../images/yard10.svg'
import Yard15 from '../../images/yard15.svg'
import Yard20 from '../../images/yard20.svg'
import Yard30 from '../../images/yard30.svg'
import YardLive from '../../images/yardlive.svg'
import YellowRight from '../../images/rightsign-yellow.svg'
import RedCross from '../../images/red-cross.svg'
import logoCurb from '../../images/logo.svg'
import logoCurbOnly from '../../images/curbside-icon.png'
import IcTruck from '../../images/truckicon.svg'

import Author1 from '../../images/author-1.svg'
import Author2 from '../../images/author-2.svg'
import Author3 from '../../images/author-3.svg'
import Author4 from '../../images/author-4.svg'
import Author5 from '../../images/author-5.svg'


// const { Panel } = Collapse;
// const { TabPane } = Tabs;
const HOSTNAME = process.env.REACT_APP_API_HOSTNAME

const getMaterials = data => {
  return fetch(`${HOSTNAME}/api/orders/getMaterials`, {
    method: 'GET'
  })
  .then(res => {
    return res.json()
  })
  .then(payload => {
    return payload
  })
  .catch(error => {
    throw error
  })
}
let tickerSpeed = 1
let flickity = null
let isPaused = false

const update = () => {
  if (isPaused) return
  if (flickity.slides) {
    flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth
    flickity.selectedIndex = flickity.dragEndRestingSelect()
    flickity.updateSelectedSlide()
    flickity.settle(flickity.x)
  }
  window.requestAnimationFrame(update)
}

const pause = () => {
  isPaused = true
}

const play = () => {
  if (isPaused) {
    isPaused = false
    window.requestAnimationFrame(update)
  }
}


class Businesspage extends Component {
  constructor(props) {
    super(props)
    this.slideshowEl = null
    this.state = {
      companyname: "",
      contactperson: "",
      email: "",
      phone: "",
      streetaddress: "",
      city: "",
      zipcode: "",
      state: "",
      sendbutton:true,
      err: {},
      streetNo: '',
      radio: 'business',
      materials: {}
    }
  }

  componentDidMount= async(e)=> {
    // Play with this value to change the speed
    // var d = document;
    // var s = d.createElement("script");
    // s.type = "text/javascript"
    // s.src="https://unpkg.com/flickity@2.2.1/dist/flickity.pkgd.min.js"
    // d.getElementsByTagName("head")[0].appendChild(s);

    setTimeout(function(){
      this.slideshowEl = document.querySelector('.js-slideshow')

      let slideshowEl = this.slideshowEl
      flickity = new Flickity(slideshowEl, {
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: false,
        wrapAround: true,
        selectedAttraction: 0.015,
        friction: 0.25
      })
      flickity.x = 0

      slideshowEl.addEventListener('mouseenter', pause, false)
      slideshowEl.addEventListener('focusin', pause, false)
      slideshowEl.addEventListener('mouseleave', play, false)
      slideshowEl.addEventListener('focusout', play, false)

      flickity.on('dragStart', () => {
        isPaused = true
      })

      update()
    }, 2000);
    let value  = await getMaterials()
    this.setState({ materials: value.data })
  }

  formatNumber(number) {
    const nfObject = new Intl.NumberFormat('en-US');
    const output = nfObject.format(number);
    return output
  }

  render() {
    // var settings = {
    //   dots: false,
    //   arrow: false,
    //   autoplay: false,
    //   speed: 500,
    //   centerMode: true,
    //   centerPadding: '60px',
    //   slidesToShow: 2,
    //   responsive: [
    //     {
    //       breakpoint: 980,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         centerMode: false,
    //         centerPadding: '0px',
    //       }
    //     },
    //     {
    //       breakpoint: 480,
    //       settings: {
    //         slidesToShow: 1,
    //         slidesToScroll: 1,
    //         centerMode: false,
    //         centerPadding: '0px',
    //       }
    //     }
    //   ]
    // };

    const { materials } = this.state
    return (
      <div>
        <Header/>
        <section className="aboutus__section businessbanner">
          <img src={BusinessBanner} alt="" />
        </section>
        <div className="clearfix"/>

        <section className="services__section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="right__section order-sm-12">
                  <h2>Business Information </h2>
                  <h3>Fill out the brief form below so one our team members can get you setup with an enterprise account</h3>
                  <BusinessForm />
                    {/* <div className="form-group">
                      <label className="services__label">What’s the name of your company?</label>
                      <input
                        type="text"
                        placeholder="Enter your comapany name"
                        className="services__input"
                        name='companyname'
                        value={ this.state.companyname }
                        onChange={(event) => this.handleChange(event)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="services__label">What’s your name?</label>
                      <input
                        type="text"
                        placeholder="Enter your first and last name"
                        className="services__input"
                        name='contactperson'
                        value={ this.state.contactperson }
                        onChange={(event) => this.handleChange(event)}
                      />
                    </div>
                    <div className="form-group">
                      <label className="services__label">What number can we reach you at?</label>
                      <InputMask
                        type="text"
                        guide={false}
                        keepCharPositions={false}
                        placeholder="Enter your phone number"
                        className="services__input"
                        name="phone"
                        value={this.state.phone}
                        mask={phoneNumberMask}
                        onChange={(event) => this.handleChange(event)}
                      />
                      <p className="text-danger">{err && err.phone ? err.phone : ""}</p>
                    </div>
                    <div className="form-group">
                      <label className="services__label">What’s your email address?</label>
                      <input
                        type="text"
                        placeholder="Enter your email address"
                        className="services__input"
                        name="email"
                        value={this.state.email}
                        onChange={(event) => this.handleChange(event)}
                      />
                      <p className="text-danger">{err && err.email ? err.email : ""}</p>
                    </div>
                    <div className="form-group">
                      <label className="services__label">Where is your company located?</label>
                      {this.state.load && window.google?
                      <Geosuggest
                        ref={el=>this._geoSuggest=el}
                        placeholder="Enter your company address"
                        inputClassName="services__input"
                        initialValue={this.state.displayaddress}
                        onChange={this.onChange.bind(this)}
                        onSuggestSelect={this.onSuggestSelect.bind(this)}
                        onSuggestNoResults={this.onSuggestNoResults.bind(this)}
                        location={new window.google.maps.LatLng(40.730610, -73.935242)}
                        radius="100"
                        country={"us"}
                        highlightMatch={false} />
                        : '' }
                    </div>
                    <button
                      onClick={this.onSubmit.bind(this)}
                      disabled={this.buttonDisabled()}
                      className="btn btn__dark">Submit
                    </button> */}
                </div>


                <div className="left__section order-sm-1">
                  <h1>Curbside for Business</h1>
                  <p>
                    Debris removal is a big expense to most contractors. Our transparent service methods will help optimize your project.
                  </p>
                  <p>
                    Our end-to-end commercial disposal service is easy to use and strategically incorporates technology to anticipate all the needs of a major construction site.
                  </p>
                  <p>
                    Feel confident our recycling stations will better serve you and our community. We manage your debris to uphold environmental best practices, while offering you the lowest cost at full transparency.
                  </p>

                  <h1>How it Works</h1>
                  <div className="how__works">
                    <div className="left__img">
                      <img src={ExpertImg} alt="" />
                    </div>
                    <div className="right__txt">
                      <h3 className="heading">EXPERT CONSULTATION</h3>
                      <p className="subheading">Best in class consultants on hand to help with the process.</p>
                    </div>
                    </div>

                  <div className="how__works">
                    <div className="left__img">
                      <img src={LiveImg} alt="" />
                    </div>
                    <div className="right__txt">
                      <h3 className="heading">Fully integrated internet of things</h3>
                      <p className="subheading">Around the clock monitoring to detect dumpster capacity.</p>
                    </div>
                  </div>

                  <div className="how__works">
                    <div className="left__img">
                      <img src={DataImg} alt="" />
                    </div>
                    <div className="right__txt">
                      <h3 className="heading">DATA TRANSPARENCY</h3>
                      <p className="subheading">Fully transparent data, so you are always informed.</p>
                    </div>
                  </div>

                  <div className="how__works">
                    <div className="left__img">
                      <img src={ResImg} alt="" />
                    </div>
                    <div className="right__txt">
                      <h3 className="heading">RESPONSIBLE RECYCLING</h3>
                      <p className="subheading">Our approach is to be as sustainable as possible. <Link to="/manifest-report">See sustainability report</Link></p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="enterprise-tech">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>Our Enterprise Technology Creates an Enjoyable Experience</h2>
                <p>
                  No one has done what we have. Our custom platform is the only comprehensive, end-to-end solution that involves the
                  entire dumpster delivery operation. We fully integrated our cloud based ordering platform which allows us to collect
                   high level data points that no other debris company can. This allows us to efficiently communicate with our customers
                    and provide the highest level of service and optimization.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="threeimg">
                  <div className="centerimg"><img src={CenterImg} alt="" /></div>
                  <div className="leftimg"><img src={LeftImg} alt="" /></div>
                  <div className="rightimg"><img src={RightImg} alt="" /></div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="enterprise">

                  <ul>
                    <li>
                      <div className="left__img">
                        <img src={ImgPlace} alt="" />
                      </div>
                      <div className="right__txt">
                        <h3 className="heading">Place & Manage Orders </h3>
                        <p className="subheading">Place and view current and past orders in your intuitive mobile friendly portal. You can plan
                    your orders and exchanges right through the portal.</p>
                      </div>
                    </li>

                    <li>
                      <div className="left__img">
                        <img src={ImgDriver} alt="" />
                      </div>
                      <div className="right__txt">
                        <h3 className="heading">Driver Tablets</h3>
                        <p className="subheading">All of our drivers carry tablets, so they can instantaneously update information on the site
                         to ensure Curbside’s data is always accurate.</p>
                      </div>
                    </li>

                    <li>
                      <div className="left__img">
                        <img src={ImgCloud} alt="" />
                      </div>
                      <div className="right__txt">
                        <h3 className="heading">Cloud based GPS & Live ETA</h3>
                        <p className="subheading">Safety and efficiency are our main priorities. We use a fleet management software to live track
                        Curbside trucks so you know when they will arrive and we ensure they are driving safe.</p>
                      </div>
                    </li>

                    <li>
                      <div className="left__img">
                        <img src={ImgSensors} alt="" />
                      </div>
                      <div className="right__txt">
                        <h3 className="heading">Sensors</h3>
                        <p className="subheading">These sensors are equipped with GPS to provide the exact location of the dumpster, allow us
                        to monitor the fullness of the dumpster, and receive a live feed of what is being dumped.</p>
                      </div>
                    </li>
                  </ul>

                  <div className="showinmobile"><img src={RightImg} alt="" /></div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="sustainability__black">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <h4>Sustainability is part of our core</h4>
                <p>
                  We believe companies should be responsible for holding themselves and their customers accountable in terms of our
                  impact on our environemnt.  That's why we publish near-realtime updates to our sustainability numbers to help track
                   our progress, together.
                </p><Link to="/manifest-report">
                <button className="btn__report">See full sustainability report</button></Link>
              </div>
              <div className="col-md-5">
                <h6>recycled in the last 12 months as of TODAYs Date</h6>
                <ul>
                  <li>
                    {this.formatNumber(_.get(materials, 'metal', ''))} <sub>tons</sub>
                    <h6>Metal</h6>
                  </li>
                  <li>
                    {this.formatNumber(_.get(materials, 'plastic', ''))} <sub>tons</sub>
                    <h6>Plastic</h6>
                  </li>
                  <li>
                    {this.formatNumber(_.get(materials, 'paperCardboard', ''))} <sub>tons</sub>
                    <h6>Paper/Cardboard</h6>
                  </li>
                  <li>
                    {this.formatNumber(_.get(materials, 'wood', ''))} <sub>tons</sub>
                    <h6>Wood</h6>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>



        <section className="different__section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <h2>How we’re different</h2>
                <p>From high rise building developments to home renovations, we provide disposal services that comply with
                site requirements suited for a range of needs.</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <ul>
                  <li>
                    <img src={YardMini} alt="" />
                    <h4>Mini</h4>
                    <h6>Size: 5 x 4 x 2 ft</h6>
                  </li>
                  <li>
                    <img src={Yard10} alt="" />
                    <h4>10 Yards</h4>
                    <h6>Size: 11 x 8 x 4 ft</h6>
                  </li>
                  <li>
                    <img src={Yard15} alt="" />
                    <h4>15 Yards</h4>
                    <h6>Size: 18 x 8 x 4 ft</h6>
                  </li>
                  <li>
                    <img src={Yard20} alt="" />
                    <h4>20 Yards</h4>
                    <h6>Size: 22 x 8 x 4 ft</h6>
                  </li>
                  <li>
                    <img src={Yard30} alt="" />
                    <h4>30 Yards</h4>
                    <h6>Size: 22 x 8 x 6 ft</h6>
                  </li>
                  <li>
                    <img src={YardLive} alt="" />
                    <h4>Live Load</h4>
                    {/* <h6>Size: 22 x 8 x 6 ft</h6> */}
                  </li>
                </ul>
              </div>
            </div>






            <div className="row">
              <div className="col-md-12">

                    <table className="w100">
                    <thead>
                      <tr>
                        <td></td>
                      <td className="text-center">
                        <span className="header__heading desktop__td"><img src={logoCurb} alt="" />Curbside for Business</span>
                        <span className="header__heading mobile__td"><img src={logoCurbOnly} alt="" />Curbside</span>
                        </td>
                      <td className="text-center">
                        <span className="header__blurheading desktop__td"><img src={IcTruck} alt="" />Traditional Debris Management Companies</span>
                        <span className="header__blurheading mobile__td"><img src={IcTruck} alt="" />Other Companies</span>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="borders-top">
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                        <tr>
                          <td>Client Facing Web Portal & App</td>
                            <td className="l-r-border text-center"><img src={YellowRight} alt="" /></td>
                            <td className=" text-center"><img src={RedCross} alt="" /></td>
                        </tr>
                        <tr>
                          <td>Order Tracking/ Notifications</td>
                          <td className="l-r-border text-center"><img src={YellowRight} alt="" /></td>
                            <td className=" text-center"><img src={RedCross} alt="" /></td>
                        </tr>
                        <tr>
                          <td>On-Demand Reporting</td>
                            <td className="l-r-border text-center"><img src={YellowRight} alt="" /></td>
                            <td className=" text-center"><img src={RedCross} alt="" /></td>
                        </tr>
                        <tr>
                          <td>Detailed Job Manifests</td>
                            <td className="l-r-border text-center"><img src={YellowRight} alt="" /></td>
                            <td className=" text-center"><img src={RedCross} alt="" /></td>
                        </tr>
                        <tr>
                          <td>Online Order Entry</td>
                            <td className="l-r-border text-center"><img src={YellowRight} alt="" /></td>
                            <td className=" text-center"><img src={RedCross} alt="" /></td>
                        </tr>
                        <tr className="borders-bottom">
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>

              </div>
            </div>
          </div>
        </section>

        <section className="slider__section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h2>Our Customers Become Long Time Partners</h2>
                <p>
                  Time is money. Contractors love working with us because we recognize the importance of efficient debris removal.
                </p>

                 <div className='explore__neighborhoods-inner'>

              <div className='slideshow js-slideshow'>


                <div className='slide'>
                    <div className="slider__wrapper">
                      <p>
                        With more than 15 years in the building restoration business I do not remember any company like Curbside.
                        The service that has been given to me is exceptional, the friendly staff speaks of the quality it has,
                        always trying to please the client to ensure a successful project. I can assure you that Curbside will meet your
                        expectations.
                      </p>
                      <div className="author">
                        <img src={Author1} alt="" />
                        <span>Field Superintendent // DNA Waterproofing</span>
                      </div>
                    </div>
                </div>

                <div className='slide'>
                    <div className="slider__wrapper">
                      <p>
                        I have never had such a pleasurable experience dealing with a Debris Removal Services company until Curbside.
                        They have excellent account managers and are extremely customer-centric and meticulously responsive to all our
                        needs. Before Curbside partnered with us, we had a small, ineffective debris management program. With Curbside
                        assisting us we are able to strategically identify locations that would help turn our subpar program into a more
                        efficient program.
                      </p>
                      <div className="author">
                        <img src={Author2} alt="" />
                        <span>Manager // Park Avenue Building Supply</span>
                      </div>
                    </div>
                </div>


                  <div className='slide'>
                    <div className="slider__wrapper">
                      <p>
                        We've been with Curbside for years now and they have been an absolute pleasure to work with. Being in an
                        industry that needs multiple containers per week (sometimes per day), it's been tremendous working with a company
                        that provides the flexibility we need to help us get our job done. From the office to the drivers, they are always
                        polite and professional. We are even more excited about the new things to come.
                      </p>
                      <div className="author">
                        <img src={Author3} alt="" />
                        <span>Co-Managing Director // Pink Sparrow Scenic</span>
                      </div>
                    </div>
                  </div>

                  <div className='slide'>
                    <div className="slider__wrapper">
                      <p>
                        We have been working with Curbside and couldn’t be happier. They are professional, courteous, and most of all
                        reliable. Our business just would not run the same without them!
                      </p>
                      <div className="author">
                        <img src={Author4} alt="" />
                        <span>Manager // Cercone Exterior Renovations</span>
                      </div>
                    </div>
                  </div>

                  <div className='slide'>
                    <div className="slider__wrapper">
                      <p>
                        Curbside keeps our jobs moving. They are always available when called and always work with our schedule
                        even on last minute deliveries or pickups. I have never worked with such a reliable container company as Curbside.
                      </p>
                      <div className="author">
                        <img src={Author5} alt="" />
                        <span>Office Lead // Volmar Construction</span>
                      </div>
                    </div>
                  </div>


              </div>
            </div>

              </div>
            </div>
          </div>
        </section>












      </div>
    );
  }
}

export default Businesspage;
