import React from "react"

import Layout from "../components/layout"
import Business from "../components/marketing/business"
import SEO from "../components/seo"

const Businesspage = () => (
  <Layout>
    <SEO title="Business" />
    <Business/>
  </Layout>
)

export default Businesspage
